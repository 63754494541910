import React from 'react'

import Layout from '../components/layout'
import withRoot from '../withRoot'

import MaterialMDXProvider from '../material-mdx-provider'
import TermsMdx from '../terms.mdx'

const TermsofServicePage = ({ location: { pathname }}) => (
  <Layout title="Terms of Service" pathname={pathname}>
    <MaterialMDXProvider>
      <TermsMdx />
    </MaterialMDXProvider>
  </Layout>
)

export default withRoot(TermsofServicePage)
