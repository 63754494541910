import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Terms`}</p>
        <p parentName="li">{`By accessing our app, Reach FI, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Reach FI. The materials contained in Reach FI are protected by applicable copyright and trademark law.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use License`}</p>
        <p parentName="li">{`a. Permission is granted to temporarily download one copy of Reach FI per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`modify or copy the materials;`}</li>
          <li parentName="ol">{`use the materials for any commercial purpose, or for any public display (commercial or non-commercial);`}</li>
          <li parentName="ol">{`attempt to decompile or reverse engineer any software contained in Reach FI;`}</li>
          <li parentName="ol">{`remove any copyright or other proprietary notations from the materials; or`}</li>
          <li parentName="ol">{`transfer the materials to another person or "mirror" the materials on any other server.`}</li>
        </ol>
        <p parentName="li">{`b. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Kamran Ayub at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.`}</p>
      </li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Disclaimer`}</p>
        <p parentName="li">{`a. The materials within Reach FI are provided on an 'as is' basis. Kamran Ayub makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.`}</p>
        <p parentName="li">{`b. Further, Kamran Ayub does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Reach FI.`}</p>
      </li>
    </ol>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Limitations`}</p>
        <p parentName="li">{`In no event shall Kamran Ayub or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Reach FI, even if Kamran Ayub or a Kamran Ayub authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Accuracy of materials`}</p>
        <p parentName="li">{`The materials appearing in Reach FI could include technical, typographical, or photographic errors. Kamran Ayub does not warrant that any of the materials on Reach FI are accurate, complete or current. Kamran Ayub may make changes to the materials contained in Reach FI at any time without notice. However Kamran Ayub does not make any commitment to update the materials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Links`}</p>
        <p parentName="li">{`Kamran Ayub has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Kamran Ayub of the site. Use of any such linked website is at the user's own risk.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Modifications`}</p>
        <p parentName="li">{`Kamran Ayub may revise these terms of service for its app at any time without notice. By using Reach FI you are agreeing to be bound by the then current version of these terms of service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Governing Law`}</p>
        <p parentName="li">{`These terms and conditions are governed by and construed in accordance with the laws of Minnesota and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      